/* 
* 업무구분: 고객 
* 화면 명: MSPCM136P 
* 화면 설명: 비대면동의 요청사항 
* 화면 접근권한: 일반사용자 
*/
<template>
  <ur-page-container title="" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="customer-info-txt txt-center" style="padding-top: 0 !important; padding-bottom: 0 !important;">
          <p class="ment" style="margin: 0;">비대면 동의는 고객의 요청이 있는 경우에 발송할 수 있습니다. 해당고객의 요청 사항을 확인해주세요.</p>
        </div>
        <div class="ns-bottom-sheet" style="width: 100%;">
          <div class="untact-pop-box">
            <p class="untact-txt2" style="color: black; font-weight: bold;">요청방법</p>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-radio-list w100" style="gap: 5px;">
              <mo-radio v-model="ltrqMthod" value="전화">전화</mo-radio>
              <mo-radio v-model="ltrqMthod" value="문자">문자</mo-radio>
              <mo-radio v-model="ltrqMthod" value="대면">대면</mo-radio>
            </ur-box-container>
          </div>
          <div class="untact-pop-box">
            <p class="untact-txt2" style="color: black; font-weight: bold;">요청일시</p>
            <ur-box-container alignV="start" componentid="" direction="row" class="fexTy1 flex_nowrap">
              <mo-date-picker class="full" v-model="cnsntReqYmd" :min-date="minDate" :max-date="maxDate" placeholder="" ref="date1" format="yyyy-MM-dd" />
            </ur-box-container>
          </div>
          <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_CancelPop" class="ns-btn-round white">취소</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue">확인</mo-button>
            </div>
          </ur-box-container>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import moment from "moment";

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM136P",
  screenId: "MSPCM136P",
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      ltrqMthod: "전화",
      cnsntReqYmd: '1970-01-01', // 오늘
      maxDate: '1970-01-01', // 요청최대일시
      minDate: '1970-01-01', // 요청최소일시
    };
  },
  created() {
    this.$commonUtil.getServerTime().then(response => {
      const today = moment(today).format("YYYY-MM-DD");
      this.cnsntReqYmd = today;
      this.maxDate = today;
      this.minDate = moment(today).add(-1, 'months').format("YYYY-MM-DD");
    });
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_ConfirmPop() {
      let lv_Param = {
        ltrqMthod: this.ltrqMthod,
        cnsntReqYmd: this.cnsntReqYmd,
      };
      this.$emit("confirmPopup", lv_Param);
    },
    fn_CancelPop() {
      this.$emit("cancelPopup");
    },
  },
};
</script>
