/*
* 업무구분: 알림톡 발송 안내화면
* 화면 명: MSPCM134P
* 화면 설명:
* 화면 접근권한:
*/
<template>
   <ur-page-container title="비대면 동의 전송" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="customer-info-txt txt-center" style="padding-top: 0 !important; padding-bottom: 0 !important;">
          <p class="ment" style="margin: 0; font-weight: bold;">{{custNm}} / {{$bizUtil.telNoWithHyphen(celno)}}</p>
        </div>
        <div class="ns-bottom-sheet">
              <div class="untact-pop-box">
                <p class="untact-txt1">{{custNm}} 고객님에게 아래의 동의 요청을 보내시겠습니까?</p>
                <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check">
                  <mo-checkbox v-model="value1">필수컨설팅 동의</mo-checkbox>
                  <mo-checkbox v-model="value2">마케팅 동의</mo-checkbox>
                </ur-box-container>
                <p class="untact-txt2">※ 비대면 동의는 사전에 고객의 요청이 있을 경우에만 발송할 수 있으며, 발송 전후 고객에게 안내 바랍니다.</p>
              </div>
                <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_CancelPop" class="ns-btn-round white">취소</mo-button>
                    <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPop" class="ns-btn-round blue"
                      :disabled="!(value1 || value2)">전송</mo-button>
                  </div>
                </ur-box-container>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM134P",
    screenId: "MSPCM134P",
    components: {},
    props: {
      custNm: String,
      celno: String
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        value1: true,
        value2: true
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {      
      fn_ConfirmPop () {
        let infoPrcusCnsntTypCd = '18'
        if (this.value1 && !this.value2) { // 필컨만 선택
          infoPrcusCnsntTypCd = '17'
        } else if (!this.value1 && this.value2) { // 마동만 선택
          infoPrcusCnsntTypCd = '19'
        }

        let lv_Param = {
          infoPrcusCnsntTypCd: infoPrcusCnsntTypCd
        }
        this.$emit('confirmPopup', lv_Param)
      },
      fn_CancelPop () {
        this.$emit('cancelPopup')
      }
    }
  }
</script>